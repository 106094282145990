var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12 col-lg-12" },
      [
        _c(
          "c-card",
          {
            staticClass: "cardClassDetailInfo",
            attrs: { title: "예비품 기본정보", topClass: "topcolor-orange" },
          },
          [
            _c(
              "template",
              { slot: "card-button" },
              [
                _c(
                  "q-btn-group",
                  { attrs: { outline: "" } },
                  [
                    _vm.editable
                      ? _c("c-btn", {
                          attrs: { label: "LBLSAVE", icon: "save" },
                          on: { btnClicked: _vm.saveInfo },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-plant", {
                    attrs: {
                      required: true,
                      editable: _vm.editable,
                      type: "edit",
                      name: "plantCd",
                    },
                    model: {
                      value: _vm.spareParts.plantCd,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "plantCd", $$v)
                      },
                      expression: "spareParts.plantCd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      editable: _vm.editable,
                      label: "예비품명",
                      required: true,
                      name: "spareNm",
                    },
                    model: {
                      value: _vm.spareParts.spareNm,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "spareNm", $$v)
                      },
                      expression: "spareParts.spareNm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      required: true,
                      editable: _vm.editable,
                      label: "용도",
                      name: "purpose",
                    },
                    model: {
                      value: _vm.spareParts.purpose,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "purpose", $$v)
                      },
                      expression: "spareParts.purpose",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      editable: _vm.editable,
                      label: "용량",
                      name: "volume",
                    },
                    model: {
                      value: _vm.spareParts.volume,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "volume", $$v)
                      },
                      expression: "spareParts.volume",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      required: true,
                      editable: _vm.editable,
                      label: "동력",
                      type: "number",
                    },
                    model: {
                      value: _vm.spareParts.power,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "power", $$v)
                      },
                      expression: "spareParts.power",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      required: true,
                      editable: _vm.editable,
                      label: "등록수량",
                      type: "number",
                      name: "regCount",
                    },
                    model: {
                      value: _vm.spareParts.regCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "regCount", $$v)
                      },
                      expression: "spareParts.regCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-3" },
                [
                  _c("c-text", {
                    attrs: {
                      required: true,
                      editable: _vm.editable,
                      label: "제조회사",
                      name: "inspCycle",
                    },
                    model: {
                      value: _vm.spareParts.inspCycle,
                      callback: function ($$v) {
                        _vm.$set(_vm.spareParts, "inspCycle", $$v)
                      },
                      expression: "spareParts.inspCycle",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }